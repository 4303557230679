import { Col, Container, Row } from "react-bootstrap";
import { CardLayout } from "../../components/Card/Card";
import oliva from '../../assets/pics/services/oliva.png';
import pop from '../../assets/pics/services/pop.png';
import massage from '../../assets/pics/services/massage.png';
import "./Services.css";

const Services = () => {
    return (
        <>
            <Container id="services" className="common-content-wrap">
                <h1>szolgáltatásaink</h1>
                <p className="text-center"></p>
                <Row xs={1} md={2} xl={3} xxl={3} className="g-4 justify-content-md-center">
                    <Col md="auto">
                        <CardLayout img={pop} title={"Helyszinen megvasarolható termékek"} href={null} desc={"Rövid leírás az adott szolgaltatasról. Ide csak pár mondat kell..."} />
                    </Col>
                    <Col md="auto">
                        <CardLayout img={massage} title={"Masszázs"} href={null} desc={"Rövid leírás az adott szolgaltatasról. Ide csak pár mondat kell, mert a kártyára kattintva az edzés aloldalára jut. Ott lesz bővebb infó."} />
                    </Col>
                    <Col md="auto">
                        <CardLayout img={oliva} title={"oliva agora termékek"} href={null} desc={"Rövid leírás az adott szolgaltatasról. Ide csak pár mondat kell, mert a kártyára kattintva az edzés aloldalára jut. Ott lesz bővebb infó."} />
                    </Col>
                </Row>
            </Container>
        </>
    );
};

export default Services;
