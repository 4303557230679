import pa from '../assets/pics/trainers/pongracz_adam.png';
import mm from '../assets/pics/trainers/mm.jpg';
import vm from '../assets/pics/trainers/vm.jpg';
import ssz from '../assets/pics/trainers/ssz.jpg';

import cross from '../assets/pics/workouts/cross.png';
import bjj from '../assets/pics/workouts/bjj.jpg';
import bell from '../assets/pics/workouts/bell.png';

export const COACHES = {
    pongracz_adam: {
        personaltrainer: true,
        href: "coaches/pongracz_adam",
        familyName: "Pongrácz",
        forName: "Ádám",
        desc: `Pongrácz Ádám a Barakk fitness alapítója és termünk vezetőedzője. Pályafutását birkózóként kezdte, amiben számos utánpótlás magyarbajnokságot szerzett és válogatottként képviselte hazánk színeit.
        Még aktív pályafutása alatt végzett birkózó edzőként a TF-en. Aktív pályafutása számos sérülés következtében véget ért, de előnye is volt ennek hiszen a sérülések rehabilitációja során megismerkedett rengeteg olyan technikával melyek segítségével még hatékonyabban edzheti amatőr és élsportolóit.
        Ádám az idő előrehaladtával számos területen képezte magát. Ilyen többek között a személyi edzői, fitnesz, TRX és FMS képzések.
        A terem irányítása mellett crosstraining-et és birkózó edzéseket vezényel.`,
        social: [],
        profession: "Birkózó edző, Fitness edző, személyi edző, TRX edző, FMS instruktor",
        personalTrainType: "Funkcionális erőfejlesztés",
        workouts: ["crosstraining", "kettlebell"],
        img: pa,
        shortDesc: "Pongrácz Ádám a Barakk fitness alapítója és termünk vezetőedzője. Pályafutását birkózóként kezdte...",
        phone: "+36 70 904 8484",
        prices: [
            { name: "Személyi edzés", price: "8 000 HUF" },
            { name: "Páros (2 fő)", price: "6 500 HUF" },
            { name: "Kiscsoportos", price: "5 000 HUF" }
        ]
    },
    sule_szilard: {
        personaltrainer: true,
        href: "coaches/sule_szilard",
        familyName: "Süle",
        forName: "Szilárd",
        desc: `Szilárd az ország legeredményesebb brazil jiu-jitsu versenyzője.<br/><br/>

        2019-ben az első magyar lett, aki a legnívósabb BJJ szervezet (IBJJF) versenyének legfelsőbb (fekete öv, felnőtt korosztály) kategóriájában nyílt versenyt nyert.<br/> 
        2022-ben az IBJJF Európa-bajnokságán ezüstérmet szerzett szintén a legfelsőbb kategóriában. <br/>
        A Barakk Fitness & Fight Clubban hétköznap délutánonként tart gi és no-gi BJJ edzéseket kezdőknek és haladóknak. <br/><br/>
        
        Főbb nemzetközi eredmények:<br/>
        2022 IBJJF European Championship, fekete öv, felnőtt, 2. hely<br/>

        2022 IBJJF London Fall Open, fekete öv, felnőtt, 1. hely<br/>

        2022 IBJJF London Fall Open, fekete öv, felnőtt, absolute, 2. hely<br/>

        2019 IBJJF Rome Fall Open, fekete öv, felnőtt, 1. hely<br/>

        2020 IBJJF London Winter Open, fekete öv felnőtt, 1. hely<br/>

        2020 IBJJF London Winter Open, fekete öv, felnőtt, absolute, 2. hely<br/>

        2022 IBJJF Rome Open, fekete öv felnőtt, 2. hely<br/>

        2020 IBJJF Munich Open, fekete öv, felnőtt, 2. hely<br/>

        2020 IBJJF Munich Open, fekete öv, felnőtt, absolute, 2. hely<br/><br/>

        *Absolute kategória súlycsoport nélkülit jelent, ahol mindegyik súlycsoport versenyzője mérkőzik.
        `,
        social: [],
        profession: "Brazil Jiu-Jitsu edző",
        personalTrainType: "Brazil Jiu-Jitsu",
        workouts: ["bjj"],
        img: ssz,
        shortDesc: "Szilárd az ország legeredményesebb brazil jiu-jitsu versenyzője. 2019-ben az első magyar lett, aki...",
        phone: "+36 20 443 5313",
        prices: [
            { name: "Személyi edzés", price: "10 000 HUF" },
            { name: "Páros (2 fő)", price: "6 000 HUF" }
        ]
    },
    verebelyi_mark: {
        personaltrainer: false,
        href: "coaches/verebelyi_mark",
        familyName: "Verebélyi",
        forName: "Mark",
        desc: `2009 óta foglalkozom kettlebell és saját testsúlyos edzéssel, 2012-ben lettem kettlebell oktató és
        ezzel párhozamosan elvégeztem a sportedző képzést testépítés-fitness szakágon. 2012 novembere
        óta tartok csoportos foglalkozásokat<br/><br/>
        Az edzéseken a célom az egészségmegőrzés, erő és kondíció fejlesztés, mobilitás fejlesztés, melyek
        együttesen eredményezik a kívánt alakot is.<br/><br/>
        Mindenkire külön figyelmet szentelek, a kezdőkkel külön foglalkozom, amíg fel nem zárkóznak,
        folyamatosan nézem és szükség esetén javítom a technikai kivitelezést, hogy minden mozdulat
        tökéletesen tudjon rögzülni. Ügyelek arra, hogy az edzésen mindenki a saját céljának, szintjének és
        adott napi állapotának megfelelő terhelést kapjon, így előfordulhat, hogy nem mindenki ugyanazt a
        gyakorlatot csinálja.<br/><br/>
        Az edzésim kettlebellre épülő, saját testsúlyos feladatokkal kevert, a funkcionális mozgásmintákat
        erősítő foglalkozások.<br/><br/>
        Mindenkit szeretettel várok, aki fejlődni, erősödni szeretne, vagy csak kiengedni a gőzt egy fárasztó
        nap után. Utóbbi esetben az előző két dolog „melléktermékként”, de ugyanúgy jelentkezni fog.`,
        social: [],
        profession: "SFG1 Kettlebell Instruktor, Sportedző",
        workouts: "",
        img: vm,
        shortDesc: "2009 óta foglalkozom kettlebell és saját testsúlyos edzéssel, 2012-ben lettem kettlebell oktató..."
    },
    /* tendl_peter: {
        personaltrainer: false,
        href: "coaches/tendl_peter",
        familyName: "Tendl",
        forName: "Péter",
        desc: `Tendl Péter vagyok okleveles svédmasszőr, csontkovács. A kezelések során több fajta terápiát alkalmazok függően a vendég állapotától. Izomlazító masszázs, tápanyagellátást serkentő köpölyözés. Trauma utáni flossing terápia, kinesio tape . Ízületek passzív kimozgatása a megfelelő mozgástartomány visszanyerése érdekében.`,
        social: [],
        profession: "Svédmasszőr, Csontkovács",
        workouts: [],
        img: tp,
        shortDesc: "Cras sit amet nibh libero, in gravida nulla. Nulla vel metus scelerisque ante sollicitudin.",
        phone: "+36 70 904 8484",
        prices: [
            { name: "60 perc", price: "12 000 HUF" },
            { name: "30 perc", price: "7 500 HUF" }
        ]
    }, */
    menyhert_marcell: {
        personaltrainer: true,
        href: "coaches/menyhert_marcell",
        familyName: "Menyhért",
        forName: "Marcell",
        desc: `Menyhért Marcell termünk egyik thaibox edzője, fiatalon több sportban jeleskedett, végül a küzdősport mellett döntött, több mint egy évtizede foglalkozik muaythai-al.<br/><br/>
        Versenyzőként is kipróbálta magát, így valós tapasztalatot tud átadni tanítványainak amit folyamatosan gyarapít.<br/><br/>
        Edzésein megtalálja a számítását aki küzdősport ambíciókkal bír, fogyna, vagy önvédelemben szeretne fejlődni!`,
        social: ["thaibox"],
        profession: "Thai box edző",
        personalTrainType: "Thai box",
        workouts: [],
        img: mm,
        shortDesc: "Menyhért Marcell termünk egyik thaibox edzője, edzésein megtalálja a számítását aki küzdősport ambíciókkal bír, fogyna, vagy...",
        phone: "+36 20 278 0228",
        prices: [
            { name: "Személyi edzés", price: "7 000 HUF" },
            { name: "Páros (2 fő)", price: "6 000 HUF" },
            { name: "Kiscsoportos", price: "5 000 HUF" }
        ]
    },
    speth_norbert: {
        personaltrainer: true,
        href: "",
        familyName: "Spéth",
        forName: "Norbert",
        desc: null,
        social: [],
        profession: "Thai box",
        personalTrainType: "Thai box",
        workouts: ["thaibox"],
        img: '',
        shortDesc: "",
        phone: "+36 30 589 6209",
        prices: [
            { name: "Személyi edzés", price: "10 000 HUF" },
            { name: "Páros (2 fő)", price: "7 500 HUF" },
            { name: "Kiscsoportos", price: "6 000 HUF" }
        ]
    },
}


export const WORKOUTS = {
    crosstraining: {
        href: "workouts/crosstraining",
        title: "Cross Training",
        desc: `A crosstraining egy olyan edzésformát jelent, amely kombinálja az erő-, a kardio-, és a funkcionális edzést egyetlen intenzív edzésprogramban. Ez a változatos edzésprogram rengeteg különböző gyakorlatot foglal magában, mint például a súlyzós edzések, a futás, az ugrókötelezés, a boxolás és a tornagyakorlatok. A crosstraining célja az erőnlét, az állóképesség és az izmok fejlesztése, valamint a zsírégetés és a testsúlycsökkentés elősegítése.
        <br/><br/>
        A crosstraining edzésprogramjai általában nagy intenzitásúak és rövidek, általában csak 60 perc vagy annál rövidebb időtartamúak. Az edzések folyamatosan változnak és kihívást jelentenek az izmoknak és az állóképességnek, így segítik a fejlődést és a változatosságot az edzésben.
        <br/><br/>
        A crosstraining széles körben elterjedt edzésforma, és az edzőteremek mellett számos csoportos órán és online edzések keretében is elérhető. Ha sokoldalú edzésformát keresel, ami hatékonyan fejleszti az erőnlétedet és állóképességedet, akkor a crosstraining lehet a tökéletes választás számodra.`, coaches: ["pongracz_adam"],
        img: cross,
        shortDesc: "A crosstraining egy olyan edzésformát jelent, amely kombinálja az erő-, a kardio-, és a funkcionális edzést egyetlen intenzív edzésprogramban."
    },

    /* thai_box: {
        href: "workouts/thai_box",
        title: "Thai box",
        desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed arcu vel nibh aenean nunc cum maecenas sed rhoncus. Sed imperdiet diam, faucibus diam tellus elit elit tempor, auctor. Nunc, facilisis nibh varius diam tempus, faucibus ut faucibus. Vivamus consectetur adipiscing aliquam consequat bibendum non sed diam. Tortor elit ut nunc, neque, id in vitae, nec nisl. Diam, quisque odio faucibus viverra. Interdum habitant nisi, eu curabitur. Dui laoreet habitant neque non, urna. Volutpat quam semper ornare varius odio mauris sed pellentesque. Enim lorem vitae habitant aliquet at arcu, enim, facilisi arcu. Diam justo, dui id ornare gravida elementum, lectus. Nunc ultrices hendrerit amet, dui at sit luctus.",
        coaches: [],
        img: box,
        shortDesc: "Rövid leírás az adott edzéstípusról. Ide csak pár mondat kell, mert a kártyára kattintva az edzés aloldalára jut. Ott lesz bővebb infó."
    }, */

    bjj: {
        href: "workouts/bjj",
        title: "brazilian jiu jitsu",
        desc: `A brazil jiu-jitsu (BJJ) egy harcművészet és küzdősport, amely Brazíliából ered, ahol 1920 körül hozták létre.<br/><br/>
        A brazil jiu-jitsu sportirányzata földharc irányzat, amiben ütések és rúgások nélkül küzdünk a másikkal. Az alapgondolat az, hogy ha le akarunk győzni egy nagyobb és erősebb támadót, akkor minél közelebb kell kerülni hozzá, ezáltal minimalizálhatjuk a támadóerejét. Ezután a földre kell őt vinni, ahol feladásra kényszerítő technikák sokasága áll rendelkezésünkre ahhoz, hogy a támadót jobb belátásra bírjuk. A földön számtalan manőverezési lehetőségünk van, hogy ún. domináns pozícióba kerüljünk, vagyis, hogy az ellenfél ne tudjon minket megsebesíteni.
        <br/><br/>
        A BJJ technikák tehát a következő csoportba sorolhatóak:<br/>
        -földrevitel<br/>
        -pozícionálás<br/>
        -feladásra kényszerítés.<br/>
        <br/><br/>
        A brazil jiu-jitsut elterjesztő Gracie család volt az MMA (kevert harcművészetek) megalkotója, amikor az első UFC gálán különböző harcművészetek gyakorlóit hívták meg, hogy egy szabályokat nélkülöző versenyen bizonyítsák be a BJJ hatékonyságát. Itt a brazil jiu-jitsus Royce Gracie mindegyik, sokszor jóval nehezebb ellenfelét feladásra kényszerítette és nyerte meg a tornát 1993-ban.`,
        coaches: ["sule_szilard"],
        img: bjj,
        shortDesc: "A brazil jiu-jitsu sportirányzata földharc irányzat, amiben ütések és rúgások nélkül küzdünk a másikkal."
    },

    kettlebell: {
        href: "workouts/kettlebell",
        title: "kettlebell",
        desc: `A kettlebellről:
        A kettlebell pár száz évvel ezelőtt egyszerű terménymérő ellensúly volt, amit gabonaméréshez
        használtak. Formáját tekintve a fül nagysága és mérete nem változott, viszont a súlyok mérete,
        alakja, formája különbözött.<br/><br/>
        Használat közben a munkások folyamatos, dinamikus mozgást végeztek, és ezáltal a teljes testüket
        megdolgoztatták. Ha pedig súlyokról beszélünk, tudjuk, milyenek a férfiak, túl akarnak tenni a
        másikon és önmaguk szórakoztatására, illetve az erejük fitogtatására elkezdtek ezekkel a súlyokkal
        különböző gyakorlatokat végezni. Például, hogy ki tud többször kinyomni egy bizonyos súlyt a feje
        fölé, vagy egyáltalán ki tudja kinyomni a legnagyobb súlyt ugyanígy.
        Teltek, múltak az évek és ezek az emberek száraz izomzatra tettek szert, megerősödtek a törzset
        merevítő izmaik, és ezek az előnyök idős korukra is megmaradtak, így mentálisan, illetve fizikálisan is
        a kortársaik fölött voltak.<br/><br/>
        Emellett volt még egy érdekes megfigyelés is, ami hozzájárulhatott hogy ezek az összefüggések
        összeértek. A mezőgazdaságban lévő súlyok hitelességének felügyeletét kispapok látták el. Mint
        szent ereklyét őrizték a hivatalos hitelesített súlyokat. Ezekkel járták a falvakat, és időről időre
        ellenőrizték “tárázták” a mezőgazdaságban használatos súlyokat. Ezek kispapok is hasonló testi
        adottságokkal rendelkeztek, mint a mezőgazdasági munkások.<br/><br/>
        
        Ezért megvizsgálták, hogy mi lehet ennek az oka, és arra jutottak, hogy a folyamatos dinamikus
        mozgás, ami a súlyok mozgatásával történt, az érrendszerre és az idegrendszerre kedvező hatást
        gyakorolt, a test megerősödése, acélossága mellett.<br/><br/>
        GIRJA (KETTLEBELL)<br/>
        1704-ben jelent meg először a szótárukban a girja (oroszul: гиря.) kifejezés. Ennek többes száma a
        giri (гири ), amit mi a versenysportban használunk.<br/><br/> A perzsa gerani szóból származik, ami azt jelenti:
        nehéz. Ez utóbbi az ősi szláv gur szóra vezethető vissza, ami azt jelenti, hogy buborék.
        Az 1870-es években Dr. Vladislav Krayevsky megalapította Szentpéterváron az Amatőr Súlyemelő
        Egyesületet. Itt kezdtek el tudatos mozgásként foglalkozni a kettlebellel, ahol többszörösen is
        bebizonyosodott, hogy akik ezt az eszközt használták, lényegesen nagyobb erőt szereztek azoknál,
        akik hagyományos módon, saját testsúllyal, más eszközökkel<br/><br/> edzettek.<br/><br/>
        Az 1900-as években a kettlebell szinte minden orosz erőművész produkciójában benne volt, őket
        hívták és hívják a mai napig is a girevoy-oknak. Innen alakult meg a girevoy sport, amit mi manapság
        girjasportnak vagy kettlebellsportnak nevezünk.<br/>
        Nagy hagyománya volt a zsonglőrködésnek azaz, a „flow” technikáknak, amiket először cirkuszokban
        vagy vásári mulatságokban mutattak be a girevoyok.<br/>
        Ennek az „atyja” Piotr Kriloff volt.<br/>
        Pyotr Kryloff erősember, birkózó és sportoló volt, aki 1871 és 1933 között élt. Legismertebb nevén A
        kettlebell királya volt, hihetetlen erőmutatványokat hajtott végre kettlebell-ekkel cirkuszi színpadon.
        Nagyon sok rekordját a mai napig nem tudták megdönteni.<br/>
        Pjotr (más néven Pierre) Moszkvában született. Tengerésziskolába járt, és navigátorként dolgozott.<br/><br/>
        Feladta a tengerész karriert, és visszaköltözött Moszkvába, ahol cirkuszban erősemberként és
        sportolóként kezdett dolgozni.<br/><br/>
        Az orosz cári hadsereg is felfigyelt erre a dologra, és jobb híján ágyúgolyókra hegesztett fül
        segítségével kezdték el edzeni a katonákat. Jelen pillanatban is az orosz katonaság erő- és
        állóképesség-fejlesztésének eszköze.<br/>
        Van egy olyan “legenda” is, hogy először tűzszerészeket kezdtek békeidőben ezekkel a súlyokkal
        edzeni. Így alakult ki az ágyúgolyóra hegesztett fül által az a forma, amit ma verseny-kettlebell-ként
        
        ismerünk. Ezt a golyót szabványosították, (Fül vastagsága 35 mm, fül magassága 110 mm, a golyó
        átmérő 280 mm) és a mai versenyeken csak ezt a szabványt lehet használni.<br/><br/>
        Az 1950-es, 60-as és 70-es években a sportiskolák az egész Szovjetunióban megjelentek, és olcsó
        felszerelésük és minimális helyigényük miatt a „dolgozó ember” sportja lett. 1981-ben megalakult a
        Hivatalos Kettlebell Bizottság, amely minden dolgozó számára kötelezővé tette volna (de nem
        hajtotta végre) a kettlebell-képzést, mondván, hogy a munkában nagyobb teljesítményt és jóval
        kisebb orvosi költségeket jelentenének az emberek.<br/><br/>
        Több irányvonal alakult ki. Nem volt szokatlan jelenség a parkolóban autójából kettlebellt elővevő
        “oroszról”, aki félmeztelenre vetkőzve különféle gyakorlatokat kezdett végezni.<br/><br/>
        A golyó elterjedésének sikere egyértelműen egyszerűségében és hatékonyságában volt, hiszen ezt a
        súlyt bárhova el lehet szállítani, és beltéren vagy kültéren egyaránt használni.<br/><br/>
        Tehát a kettlebell az átlag ember sportja lett.<br/><br/>
        A KETTLEBELL MÉRETEK<br/><br/>
        A mezőgazdaságban az ottani mértékegység szerint használták a súlyokat, így itt is ezt vették alapul.1
        Pud =16 Kg.<br/><br/>
        Ebből adódóan hozták létre a súlyokat, ami tehát 16, 24, 32 kg-ot jelentett.<br/>
        Ezeket használták a versenyre, illetve ezek a verseny súlyok a mai napig. De mivel nem minden
        ember születik Girevoynak, létre hoztak átlagember számára is súlyokat, ami 8, 10, 12, 14, 16, 20, 24,
        28, 32 kg-ok. Illetve innen lépegetnek fölfelé a súlyok akár 72 kg-ig is.
        Ezzel párhuzamosan kialakult a profi kettlebellsport.`,
        coaches: ["verebelyi_mark", "pongracz_adam"],
        img: bell,
        shortDesc: "A kettlebell segít az erőnövelésben, az állóképesség javításában, a testtartás korrekciójában és a teljes test edzésében"
    },

    /* personal: {
        href: "workouts/personal",
        title: "Személyi edzés",
        desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed arcu vel nibh aenean nunc cum maecenas sed rhoncus. Sed imperdiet diam, faucibus diam tellus elit elit tempor, auctor. Nunc, facilisis nibh varius diam tempus, faucibus ut faucibus. Vivamus consectetur adipiscing aliquam consequat bibendum non sed diam. Tortor elit ut nunc, neque, id in vitae, nec nisl. Diam, quisque odio faucibus viverra. Interdum habitant nisi, eu curabitur. Dui laoreet habitant neque non, urna. Volutpat quam semper ornare varius odio mauris sed pellentesque. Enim lorem vitae habitant aliquet at arcu, enim, facilisi arcu. Diam justo, dui id ornare gravida elementum, lectus. Nunc ultrices hendrerit amet, dui at sit luctus.",
        coaches: [],
        img: personal,
        shortDesc: "Rövid leírás az adott edzéstípusról. Ide csak pár mondat kell, mert a kártyára kattintva az edzés aloldalára jut. Ott lesz bővebb infó."
    } */
}