import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import 'bootstrap/dist/css/bootstrap.css';
import './index.css';
import './assets/fonts/Raleway-VariableFont_wght.ttf';
import './assets/fonts/Orbitron-VariableFont_wght.ttf';
import './assets/fonts/Raleway-VariableFont_wght_900.ttf';
import './assets/fonts/Orbitron-VariableFont_wght_900.ttf';

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <App />
);
