import { Col, Container, Nav, Row } from "react-bootstrap";
import { PAGES } from "../../misc/constants";
import logo from '../../assets/pics/home_logo.svg';
import fb from '../../assets/pics/fb.svg';
import it from '../../assets/pics/insta.svg';
import yt from '../../assets/pics/youtube.svg';
import "./Footer.css";

export const Footer = () => {
    const links = Object.entries(PAGES).map((page, id) => <Nav.Link key={id} className="text-white text-uppercase nav-font" href={page[1].href}>{page[1].label}</Nav.Link>);

    return (
        <footer className="text-start text-muted">
            <section>
                <Container fluid="xl">
                    <Row>
                        <Col id="footer-company" md={12} lg={4} xl={4} className="mx-auto">
                            <div className="footer-wrap d-flex">
                                <img id="footer-logo" src={logo} alt="barakk fitness logó" />
                                <div>
                                    <p>1076 Budapest, Garay u. 3.</p>
                                    <p>+36 70 904 8484</p>
                                    <p>barakkfitness@gmail.com</p>
                                </div>
                            </div>
                        </Col>

                        <Col md={3} lg={2} xl={3} className="mx-auto">
                            <div className="footer-wrap">
                                {links.slice(0, 3).map((link, id) => <h6 key={id} className="text-md-start text-center">{link}</h6>)}
                            </div>
                        </Col>

                        <Col md={3} lg={2} xl={2} className="mx-auto">
                            <div className="footer-wrap">
                                {links.slice(3, 6).map((link, id) => <h6 key={id} className="text-md-start text-center">{link}</h6>)}
                            </div>
                        </Col>

                        <Col md={3} lg={2} xl={2} className="mx-auto mb-md-0">
                            <div className="footer-wrap">
                                {links.slice(6, 7).map((link, id) => <h6 key={id} className="text-md-start text-center">{link}</h6>)}
                            </div>
                        </Col>
                        <Col id="footer-social-wrap" md={1} lg={1} xl={1} className="mx-auto mb-md-0">
                            <div className="footer-wrap">
                                <Nav.Link href="https://www.facebook.com/barakkfitness" target="blank">
                                    <img className="footer-social" src={fb} alt="facebook logó" />
                                </Nav.Link>
                                <Nav.Link href="https://www.instagram.com/barakkfitnessclub" target="blank">
                                    <img className="footer-social" src={it} alt="instagram logó" />
                                </Nav.Link>
                                <Nav.Link href="https://www.youtube.com/@barakkfitnessfightclub8839" target="blank">
                                    <img className="footer-social" src={yt} alt="youtube logó" />
                                </Nav.Link>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </footer>
    );
};

