import { Card, Nav } from "react-bootstrap";
import "./Card.css";

export const CardLayout = (props) => {
    return (
        <Card className="brk-card mx-auto">
            <Card.Img variant="top" src={props.img} />
            <Card.Body>
                {props.type && <h5>{props.type}</h5>}
                <Card.Title>{props.title}</Card.Title>
                <Card.Text>
                    {props.desc}
                </Card.Text>
                <Nav.Link className="more-info" href={`/${props.href}`}>Bővebben</Nav.Link>
            </Card.Body>
        </Card>
    )
}