import { Nav } from "react-bootstrap";
import logo from '../../assets/pics/home_logo.svg';

import "./ShortDesc.css";
import { useEffect, useRef } from "react";

export const ShortDesc = (props) => {
    const imgRef = useRef(null);

    useEffect(() => {
        if (imgRef.current) {
            const w = imgRef.current.getBoundingClientRect().width;
            const h = imgRef.current.getBoundingClientRect().height;
            const ratio = h / w;
            if (w < h) {
                imgRef.current.style.height = '100px';
                imgRef.current.style.width = `${100 / ratio}px`;
            } else {
                imgRef.current.style.width = '100px';
                imgRef.current.style.height = `${100 / ratio}px`;
            }
        }
    }, [])

    return (
        <div className="trainer-wrap mx-auto">
            <div className="img-wrap">
                <div className="img-center">
                    <img
                        src={props.img}
                        alt={props.name}
                        ref={imgRef}
                        onError={({ currentTarget }) => {
                            currentTarget.onerror = null;
                            currentTarget.src = logo;
                        }} />
                </div>
            </div>
            <div id="short-desc">
                <h5>{props.name}</h5>
                <p>{props.desc}</p>
                <Nav.Link className="more-info" href={`/${props.href}`}>Bővebben</Nav.Link>
            </div>
        </div>
    )
}