import { Container, Navbar, Offcanvas } from "react-bootstrap";
import Nav from "react-bootstrap/Nav";
import { PAGES } from "../../misc/constants";
import logo from '../../assets/pics/home_logo.svg';
import "./Header.css";
import fb from '../../assets/pics/fb.svg';
import it from '../../assets/pics/insta.svg';
import yt from '../../assets/pics/youtube.svg';
import { useLocation } from "react-router-dom";

export const Header = () => {
	const location = useLocation();
	const links = Object.entries(PAGES).map((page, id) =>
		<Nav.Link key={id} eventKey={page[1].href} className="text-uppercase nav-font" href={page[1].href}>
			{page[1].label}
		</Nav.Link>
	).slice(0, -2);

	return (
		<Container className="wrap raleway-bold">
			<Navbar key="xxl" expand="xxl" className="pb-0">
				<Container id="navbar-inner-container" fluid className="pt-3">
					<Navbar.Brand href="/">
						<img src={logo} alt="barakk fitness logó" />
					</Navbar.Brand>
					<Navbar.Toggle aria-controls="offc-menu" className="brk-btn btn-yellow navbar-toggler collapsed text-white" />
					<Navbar.Offcanvas
						id="offc-menu"
						placement="start"
						className="pt-3"
					>
						<Offcanvas.Body>
							<Nav activeKey={`/${location.pathname.split("/")[1]}`} className="justify-content-end flex-grow-1 position-relative top-nav">
								{links}
								<div className="d-flex">
									<Nav.Link href="https://www.facebook.com/barakkfitness" target="blank">
										<img className="header-social social-icon" src={fb} alt="facebook logó" />
									</Nav.Link>
									<Nav.Link href="https://www.instagram.com/barakkfitnessclub" target="blank">
										<img className="header-social social-icon" src={it} alt="instagram logó" />
									</Nav.Link>
									<Nav.Link href="https://www.youtube.com/@barakkfitnessfightclub8839" target="blank">
										<img className="header-social social-icon" src={yt} alt="youtube logó" />
									</Nav.Link>
								</div>
							</Nav>
						</Offcanvas.Body>
					</Navbar.Offcanvas>
				</Container>
			</Navbar>
		</Container>
	);
};

