import { Col, Container, Row } from "react-bootstrap";
import { CardLayout } from "../../components/Card/Card";
import "./Workout.css";
import { ShortDesc } from "../../components/ShortDesc/ShortDesc";
import { SingUp } from "../../components/SingUp/SingUp";
import { useLocation } from "react-router-dom";
import { COACHES, WORKOUTS } from "../../misc/mocked";

const Workout = () => {
    const location = useLocation();
    let workoutName = location.pathname.split("/")[2];
    return (
        <>
            <Container id="workout" fluid="lg">
                <p id="workout-sub-title" >Edzés típusok</p>
                <h1>{`${WORKOUTS[workoutName].title}`}</h1>
                <div id="workout-main" className="d-xl-flex">
                    <div id="workout-img-wrap">
                        <img id="workout-img" src={`${WORKOUTS[workoutName].img}`} alt="Edzésről kép" />
                    </div>
                    <div id="workout-desc" dangerouslySetInnerHTML={{ __html: WORKOUTS[workoutName].desc }}>
                    </div>
                </div>
                <SingUp />
            </Container>
            <Container id="workout-trainers" fluid>
                <Container fluid="lg">
                    <h4>{`${WORKOUTS[workoutName].title}`} edzőink</h4>
                    <Row xs={1} lg={2} xxl={3} className="g-5 justify-content-center">
                        {WORKOUTS[workoutName].coaches.map((coachName) => {
                            const coach = COACHES[coachName];
                            return (
                                <Col key={coach.href}>
                                    <ShortDesc img={coach.img} name={`${coach.familyName} ${coach.forName}`} href={coach.href} desc={coach.shortDesc} />
                                </Col>
                            )
                        })}
                    </Row>
                </Container>
            </Container>
            <Container id="more-workouts">
                <h4>további edzéseink</h4>
                <Row xs={1} md={2} xl={3} xxl={4} className="g-4 justify-content-center">
                    {Object.entries(WORKOUTS).map((wo) => {
                        if (wo[0] !== workoutName)
                            return (
                                <Col key={wo[1].href}>
                                    <CardLayout img={wo[1].img} title={wo[1].title} type={"fitness"} href={wo[1].href.split("/")[1]} desc={wo[1].shortDesc} />
                                </Col>
                            )
                        return <></>
                    }
                    )}
                </Row>
            </Container>
        </>
    );
};

export default Workout;
