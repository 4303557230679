import { Button } from "react-bootstrap";
import "./Button.css";

export const Btn = (props) => {
    const outline = props.outline ? `btn-${props.color}-outline` : ``;
    return (
        <Button variant='primary' className={`brk-btn ${outline} btn-${props.color} ${props.className}`}>
            {props.label ? props.label : props.children}
        </Button>
    )
}