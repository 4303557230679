import { Col, Container, Row } from "react-bootstrap";
import "./Coaches.css";
import { ShortDesc } from "../../components/ShortDesc/ShortDesc";
import { COACHES } from "../../misc/mocked";

export const Coaches = () => {
    return (
        <>
            <Container id="coaches" className="common-content-wrap" fluid="md">
                <h1>edzőink</h1>
                <p className="text-center"></p>
                <Row xs={1} lg={2} xxl={3} className="g-5">
                    {Object.entries(COACHES).map((coach) => {
                        if (coach[1].desc) {
                            return (<Col key={coach[1].href}>
                                <ShortDesc img={coach[1].img} name={`${coach[1].familyName} ${coach[1].forName}`} href={coach[1].href} desc={coach[1].shortDesc} />
                            </Col>)
                        }
                        return <></>
                    })}
                </Row>
            </Container>
        </>
    );
}