import { Container, Modal, Row } from "react-bootstrap";
import { useState } from "react";
import { SingUp } from "../SingUp/SingUp";
import "./Timetable.css";

import timesheet from '../../assets/pics/timetable.png';

export const Timetable = (props) => {
    const [openTimetable, setOpenTimetable] = useState(false);

    return (<Container id="time_sheet" fluid>
        <h2>aktuális órarend</h2>
        <Row className="justify-content-md-center">
            <img className="mx-auto" src={timesheet} alt="Órarend" onClick={() => setOpenTimetable(true)} />
        </Row>
        {props.singUp && <div className="mt-5"><SingUp /> </div>}
        <Modal
            show={openTimetable}
            onHide={() => setOpenTimetable(false)}
            dialogClassName="modal-90w"
            centered
        >
            <img src={timesheet} alt='Órarend' />
        </Modal>
    </Container>)
}