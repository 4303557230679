import { Col, Container, Row } from "react-bootstrap";
import { ShortDesc } from "../../components/ShortDesc/ShortDesc";
import oliva from '../../assets/pics/partners/oliva.png';
import leone from '../../assets/pics/partners/leone.png';
import { PARTNERS } from "../../misc/constants";
//import "./Partners.css"

export const Partners = () => {
    return (
        <>
            <Container id="coaches" className="common-content-wrap" fluid="md">
                <h1>Partnereink</h1>
                <p className="text-center">Az edzések mellett ezen szolgáltatásokat is igénybe veheted ... stb</p>
                <Row xs={1} lg={2} xxl={3} className="g-5">
                    <Col>
                        <ShortDesc img={oliva} name={PARTNERS.oliva.label} href={PARTNERS.oliva.href} desc="Cras sit amet nibh libero, in gravida nulla. Nulla vel metus scelerisque ante sollicitudin. " />
                    </Col>
                    <Col>
                        <ShortDesc img={leone} name={PARTNERS.leone.label} href={PARTNERS.leone.href} desc="Cras sit amet nibh libero, in gravida nulla. Nulla vel metus scelerisque ante sollicitudin. " />
                    </Col>
                </Row>
            </Container>
        </>
    );
}
