import { Button, Container, Form, InputGroup, Row } from "react-bootstrap"
import "./SingUpForm.css";

const SingUpForm = () => {

    return (
        <Container id="coaches" className="common-content-wrap" fluid="md">
            <h1>Jelentkezz első ingyenes edzésedre!</h1>
            <p className="text-center">Próbáld ki csoportos edzéseink bármelyikét első alkalommal teljesen ingyen!</p>
            <Row xs={1} md={2} xl={3} xxl={3} className="mx-auto justify-content-center">
                <Form name="sing-up-for-class" method='POST' data-netlify="true" action="/contact">
                    <input type="hidden" name="form-name" value="sing-up-for-class" />

                    <Form.Group controlId="formBasicName">
                        <Form.Label htmlFor="name">Név</Form.Label>
                        <input
                            type="text"
                            id="name"
                            name="name"
                            className="form-control"
                        />
                    </Form.Group>

                    <Form.Label>Email cím</Form.Label>
                    <InputGroup>
                        <InputGroup.Text id="basic-addon1">@</InputGroup.Text>
                        <input
                            placeholder="Email cím"
                            type="email"
                            id="email"
                            name="email"
                            className="form-control"
                        />
                    </InputGroup>

                    <Form.Group controlId="formBasicMessage">
                        <Form.Label htmlFor="phone">Telefonszám</Form.Label>
                        <input
                            type="text"
                            id="phone"
                            name="phone"
                            className="form-control"
                        />
                    </Form.Group>
                    <div className="center-btn">
                        <Button color={'red'} className="brk-btn btn-red" type="submit">Elküldöm</Button>
                    </div>
                </Form>
            </Row>
        </Container>
    )
}

export default SingUpForm;