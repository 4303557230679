import { Col, Container, Row, Spinner } from 'react-bootstrap';
import "./Contact.css";
import { Wrapper, Status } from '@googlemaps/react-wrapper';
import { useEffect, useRef } from 'react';
import { SingUp } from '../../components/SingUp/SingUp';

const location = {
    lat: 47.500495148327246,
    lng: 19.079073111599264,
}
const render = (status) => {
    if (status === Status.FAILURE) return <></>;
    return <Spinner />;
};

const MyMapComponent = (props) => {
    const ref = useRef();

    useEffect(() => {
        const map = new window.google.maps.Map(ref.current, {
            center: props.center,
            zoom: props.zoom,
        });
        new window.google.maps.Marker({
            position: props.center,
            map
        });
    });

    return <div ref={ref} id="map">{props.children}</div>;
}


export const Contact = () => {
    return (
        <Container fluid="md">
            <h1 className="contact-title">Kapcsolat</h1>
            <Row className="mx-auto contact-lines justify-content-center">
                <Col md={12} lg={5}>1076 Budapest, Garay u. 3.</Col>
                <Col md={12} lg={3}>+36 70 904 8484</Col>
                <Col md={12} lg={4}>barakkfitness@gmail.com</Col >
            </Row >
            <Row className="mx-auto contact-map justify-content-center">
                <Wrapper apiKey={"AIzaSyBPkHO5UbtR5q1W9uSH84c0IujASS71qPc"} render={render}>
                    <MyMapComponent center={location} zoom={16} />
                </Wrapper>
            </Row>
            <SingUp />
        </Container >
    )
}