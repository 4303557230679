import { Col, Container, Row } from "react-bootstrap"
import { CardLayout } from "../../components/Card/Card"
import { SingUp } from "../../components/SingUp/SingUp"
import "./Coach.css";
import { useLocation } from "react-router-dom";
import { COACHES, WORKOUTS } from "../../misc/mocked";
import { useEffect, useRef } from "react";

export const Coach = () => {
    const location = useLocation();
    let coachName = location.pathname.split("/")[2];
    const imgRef = useRef(null);

    useEffect(() => {
        if (imgRef.current) {
            const w = imgRef.current.getBoundingClientRect().width;
            const h = imgRef.current.getBoundingClientRect().height;
            const ratio = h / w;
            if (w < h) {
                imgRef.current.style.height = '200px';
                imgRef.current.style.width = `${200 / ratio}px`;
            } else {
                imgRef.current.style.width = '200px';
                imgRef.current.style.height = `${200 / ratio}px`;
            }
        }
    }, [])

    return (
        <>
            <Container id="coach" fluid="lg">
                <p id="coach-sub-title" >Edzőink</p>
                <h1>{`${COACHES[coachName].familyName} ${COACHES[coachName].forName}`}</h1>
                <div id="coach-main" className="d-xl-flex">
                    <div id="coach-img-wrap">
                        <div id="coach-img-wrap">
                            <img id="coach-img" src={COACHES[coachName].img} alt="Edzésről kép" ref={imgRef} />
                        </div>
                    </div>
                    <div id="coach-desc-wrap">
                        <p id="coach-desc-title">
                            {COACHES[coachName].profession}
                        </p>
                        <p id="coach-desc" dangerouslySetInnerHTML={{ __html: COACHES[coachName].desc }}>

                        </p>
                        {/* <div id="coach-desc-social">
                            <img className="header-social social-icon" src={fb} alt="facebook logó" />
                            <img className="header-social social-icon" src={it} alt="instagram logó" />
                            <img className="header-social social-icon" src={yt} alt="youtube logó" />
                        </div> */}
                    </div>
                </div>
            </Container>
            {COACHES[coachName].workouts.length > 0 &&
                <Container id="coach-workouts">
                    <h4>{`${COACHES[coachName].familyName} ${COACHES[coachName].forName}`} edzései</h4>
                    <Row xs={1} md={2} xl={3} xxl={4} className="g-4 justify-content-center">
                        {COACHES[coachName].workouts.map((woName) => {
                            const wo = WORKOUTS[woName];
                            return (
                                <Col key={wo.href}>
                                    <CardLayout img={wo.img} title={wo.title} type={"fitness"} href={wo.href} desc={wo.shortDesc} />
                                </Col>
                            )
                        })}
                    </Row>
                </Container>
            }
            <Container id="coach-singup" fluid>
                <SingUp />
            </Container>
        </>
    )
}