import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import { Layout } from "./components/Layout/Layout";
import { Coach } from "./pages/Coach/Coach";
import { Coaches } from "./pages/Coaches/Coaches";
import { Contact } from "./pages/Contact/Contact";
import Main from "./pages/Main/Main";
import { Partners } from "./pages/Partners/Partners";
import Prices from "./pages/Prices/Prices";
import Services from "./pages/Services/Services";
import Workout from "./pages/Workout/Workout";
import Workouts from "./pages/Workouts/Workouts";
import SingUpForm from "./pages/SingUp/SingUpForm";
import { Data } from "./pages/Data/Data";

function App() {
  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route element={<Layout />}>
            <Route path="/" element={<Main />}></Route>
            <Route path="prices" element={<Prices />}></Route>
            <Route path="workouts" element={<Workouts />}></Route>
            <Route path="coaches" element={<Coaches />}></Route>
            <Route path="services" element={<Prices />}></Route>
            <Route path="services_orig" element={<Services />}></Route>
            <Route path="partners" element={<Partners />}></Route>
            <Route path="contact" element={<Contact />}></Route>
            <Route path="singup" element={<SingUpForm />}></Route>
            <Route path="data" element={<Data />}></Route>

            <Route path="workouts/crosstraining" element={<Workout />}></Route>
            <Route path="workouts/bjj" element={<Workout />}></Route>
            <Route path="workouts/box" element={<Workout />}></Route>
            <Route path="workouts/kettlebell" element={<Workout />}></Route>

            <Route path="coaches/pongracz_adam" element={<Coach />}></Route>
            <Route path="coaches/tarnoczai_mihaly" element={<Coach />}></Route>
            <Route path="coaches/menyhert_marcell" element={<Coach />}></Route>
            <Route path="coaches/sule_szilard" element={<Coach />}></Route>
            <Route path="coaches/verebelyi_mark" element={<Coach />}></Route>
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
