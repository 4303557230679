export const PAGES = {
	prices: { href: "/prices", label: "áraink és órarend" },
	workouts: { href: "/workouts", label: "edzéstípusok" },
	coaches: { href: "/coaches", label: "edzőink" },
	services: { href: "/services", label: "szolgáltatásaink" },
	//partners: { href: "/partners", label: "partnereink" },
	contact: { href: "/contact", label: "kapcsolat" },
	data: { href: "/data", label: "adatvédelem" },
	singup: { href: "/singup", label: "jelentkezés" },
};

export const PARTNERS = {
	oliva: { href: "partners/oliva_agora", label: "oliva agora" },
	leone: { href: "partners/leone_sport", label: "leone sport" }
};
