import { Col, Container, Nav, Row } from "react-bootstrap";
import "./Main.css";

import { CardLayout } from "../../components/Card/Card";
import { SingUp } from "../../components/SingUp/SingUp";
import { Btn } from "../../components/Button/Button";
import { ShortDesc } from "../../components/ShortDesc/ShortDesc";
import { Timetable } from "../../components/Timetable/Timetable";
import { PAGES } from "../../misc/constants";

/* import img01 from '../../assets/pics/gallery/01.png';
import img02 from '../../assets/pics/gallery/02.png'; */
import { COACHES, WORKOUTS } from "../../misc/mocked";
/* import { ImgCarousel } from "../../components/ImgCarousel/ImgCarousel"; */


const Main = () => {
  /* const [openGallery, setOpenGallery] = useState(false);

  const images = [
    img01,
    img02,
    img01,
    img02,
    img01,
    img02
  ] */

  return (
    <>
      <div id="banner">
        <div id="banner-text">
          <h1 className="orbitron-bold">Üdvözlünk</h1>
          <h2 className="raleway-bold">A klubban!</h2>
          <p className="mx-auto">A Barakk Fitness Club azért jött létre, hogy egy helyen egyesítse az ország legszínvonalasabb funkcionális és küzdősport edzéseit. Legyen szó erő- vagy állóképesség fejlesztésről, álló- vagy földharcról, itt megtalálod a számításaidat, akár teljesen kezdő, akár profi vagy.</p>
        </div>
      </div >
      <SingUp />
      <Container id="main-workouts">
        <h2>edzéstípusok</h2>
        <Row xs={1} md={2} xl={3} className="g-4">
          {Object.entries(WORKOUTS).map((workout) =>
            <Col>
              <CardLayout img={workout[1].img} title={workout[1].title} type={workout[1].type} href={workout[1].href} desc={workout[1].shortDesc} />
            </Col>
          )}
        </Row>
        <Row id="more-workouts" className="justify-content-xs-center">
          <div xs={4}>
            <Nav.Link href={PAGES.workouts.href} className="btn-nav-link mx-auto">
              <Btn color={'yellow'} outline={false} label="Minden edzéstípus" />
            </Nav.Link>
          </div>
        </Row>
      </Container>

      <Timetable singUp={false} />

      <Container id="trainers" fluid="lg">
        <h2>edzőink</h2>
        <Row xs={1} lg={2} xxl={3} className="g-5">
          {Object.entries(COACHES).map((coach) => {
            if (coach[1].desc) {
              return (<Col key={coach[1].href}>
                <ShortDesc img={coach[1].img} name={`${coach[1].familyName} ${coach[1].forName}`} href={coach[1].href} desc={coach[1].shortDesc} />
              </Col>)
            }
            return <></>
          })}
        </Row>
      </Container>

      {/* <Container id="main-galery" fluid>
        <h2>a terem</h2>

        <Container fluid="md">
          <Row xs={2} md={3} lg={4} xl={6} className="g-4 justify-content-xs-center">
            {images.map((img, idx) =>
              <img
                key={idx}
                src={img}
                alt={`gallery #${idx}`}
                onClick={() => {
                  setOpenGallery(true);
                }} />
            )}
          </Row>
          <Modal
            show={openGallery}
            onHide={() => setOpenGallery(false)}
            dialogClassName="modal-90w"
            centered
          >
            <ImgCarousel />
          </Modal>
        </Container>
      </Container> */}
    </>
  );
};

export default Main;
