import { Container } from "react-bootstrap";

export const Data = () => (
    <Container id="data" className="common-content-wrap" fluid="md">
        <h1>adatvédelem</h1>
        <p>A Barakk Sportegyesület (székhely: 1048 Budapest, Intarzia utca 18. fszt./1., Nyilvántartási szám: 01-02-0017145, Nyilvántartást vezeti: Fővárosi Törvényszék Cégbírósága), mint a barakkfitness.hu domainen elérhető, „BarakkFitness” elnevezésű weboldal üzemeltetője kiemelt figyelmet fordít arra, hogy adatkezelése során az információs önrendelkezési jogról és az információszabadságról szóló 2011. évi CXII. törvénynek, az egyéb jogszabályoknak, a Nemzeti Adatvédelmi és Információszabadság Hatóság (”Hatóság”) tevékenysége során kialakult adatvédelmi gyakorlatnak megfelelően, valamint az adatvédelemhez kapcsolódó fontosabb nemzetközi ajánlásokat is figyelembe véve járjon el.<br /><br />

            A Barakk Sportegyesület az információs önrendelkezési jogról és az információszabadságról szóló 2011. évi CXII. törvény (”Infotv.”) 3. § 9. pontja értelmében vett adatkezelőnek (”Adatkezelő”) minősül.<br /><br />

            Igénybe vevőnek minősül minden olyan nagykorú természetes személy, jogi személy, illetve jogi személyiség nélküli gazdasági társaság vagy egyéb szervezet felhasználó, aki az információs társadalommal összefüggően a Weboldalon regisztrál, saját fiókot/identitást hoz létre. (”Igénybe Vevő”).<br /><br />

            A Barakk Sportegyesület ezúton tájékoztatja Igénybe Vevőit, Weboldalának látogatóit az általa a Weboldallal összefüggésben kezelt személyes adatokról, a személyes adatok kezelése körében követett gyakorlatáról, a személyes adatok védelme érdekében tett szervezési és technikai intézkedéseiről, valamint az érintettek jogai gyakorlásának módjáról és lehetőségeiről, az adatkezeléssel kapcsolatos minden tényről, így különösen az adatkezelés céljáról és jogalapjáról, az adatkezelésre és az adatfeldolgozásra jogosult személyéről, az adatkezelés időtartamáról.<br /><br />

            1. Az Adatkezelő adatai, elérhetőségei<br />
            Az Adatkezelő adatai: Barakk Sportegyesület (székhely: 1048 Budapest, Intarzia utca 18. fszt./1., Nyilvántartási szám: 01-02-0017145, Nyilvántartást vezeti: Fővárosi Törvényszék Cégbírósága)<br /><br />

            2. A kezelt adatok<br />
            Az Igénybe Vevő által megadott személyes adatok közül a Barakk Sportegyesület csak azokat az információkat használja fel, amelyek az Igénybe Vevő azonosításhoz szükségesek annak érdekében, hogy tájékoztatást kaphasson a Barakk Sportegyesület fejlesztéséről, szolgáltatásairól, a Weboldalon elérhető, rendszeresen bővülő kínálatról, valamint a Barakk Sportegyesület értesítéseiről. A Weboldal használatához, a böngészéshez nem szükséges megadni adatokat, ám az időpont foglaláshoz legalább a regisztrációhoz név, telefonszám és e-mail cím szükséges.<br /><br />

            3. Az adatkezelés célja<br />
            Az Igénybe Vevőről a Barakk Sportegyesület által nyilvántartott adatok, a Barakk Sportegyesület által fenntartott adatbázisokban tárolt információk felhasználhatóak statisztikák saját, belső célra, hírlevél küldésére, illetve az Igénybe Vevőkkel való kapcsolattartás érdekében.<br /><br />

            4. Az adatkezelés jogalapja<br />
            Az adatkezelés jogalapja az Infotv. 5.§ (1) bek. a) pontja szerint az Igénybe Vevő önkéntes hozzájárulása, melyet e-mail címének megadásával, vagy a regisztráció alkalmával ad meg a Barakk Sportegyesület számára.<br /><br />

            5. Az adatkezelés időtartama<br />
            Az Igénybe Vevő által megadott adatokat a Barakk Sportegyesület addig őrzi, amíg azok törlését Igénybe Vevő nem kéri.<br /><br />

            6. Az adatokhoz hozzáférők köre<br />
            Az adatokhoz a Barakk Sportegyesület minden munkatársa hozzáférhet az Igénybe Vevőkkel történő kapcsolattartás érdekében.<br /><br />

            7. Az Igénybe Vevő jogai, adatok törlése, zárolása, helyesbítése<br />
            Igénybe Vevő bármikor jogosult az adatainak helyesbítését kérni, vagy saját felhasználói felületén módosítani azokat. Amennyiben ehhez segítséget kér, a Barakk Sportegyesület ügyfélszolgálatán keresztül nyújt ehhez segítséget. Igénybe Vevő bármikor kérheti adatai törlését, vagy zárolását.<br /><br />

            8. Jogorvoslati lehetőségek<br />
            A Barakk Sportegyesület arra törekszik, hogy az adatkezeléssel kapcsolatosan esetlegesen felmerülő vitás kérdéseket békés úton rendezze. Amennyiben a vitás kérdések békés rendezése eredménytelen marad, Igénybe vevő a Nemzeti Adatvédelmi és Információszabadság Hatósághoz (székhely: 1125 Budapest, Szilágyi Erzsébet fasor 22/C) fordulhat, egyéb igényét pedig bírósági úton érvényesítheti.<br /><br />

            9. Vonatkozó jogszabályok<br />
            Az Adatkezelő kötelezettségeire vonatkozó részletes jogszabályi rendelkezéseket, valamint a jogérvényesítés pontos módját, a Hatóság eljárását a következő törvények tartalmazzák: a 2011. évi CXII. törvény az információs önrendelkezési jogról és az információszabadságról; a gazdasági reklámtevékenység alapvető feltételeiről és egyes korlátairól szóló 2008. évi XLVIII. törvény.<br /><br />

            10. Kikötés<br />
            Az Adatkezelő fenntartja a jogot, hogy az adatkezelési tájékoztatóját időről-időre megváltoztassa. Erre különösen akkor kerülhet sor, ha a szolgáltatások köre bővül, vagy ha jogszabály azt kötelezővé teszi. Az adatkezelés megváltozása nem jelentheti a személyes adatok céltól eltérő kezelését. Az erre vonatkozó tájékoztatást az adatkezelő 15 nappal előre közzéteszi honlapján.<br /><br />
        </p>
    </Container>
)