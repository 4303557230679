import { Outlet } from "react-router-dom";
import { Footer } from "../Footer/Footer";
import { Header } from "../Header/Header";
import "./Layout.css";

export const Layout = () => {
    return (
        <div id="app-wrap">
            <Header />
            <Outlet />
            <Footer />
        </div>
    );
};
