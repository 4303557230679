import { Col, Container, Row } from "react-bootstrap";
import { CardLayout } from "../../components/Card/Card";
import "./Workouts.css";
import { WORKOUTS } from "../../misc/mocked";
const Workouts = () => {
    return (
        <>
            <Container id="workouts" className="common-content-wrap">
                <h1>edzéstípusok</h1>
                <p className="text-center"></p>
                <Row xs={1} md={2} xl={3} className="g-4">
                    <Col>
                        <CardLayout img={WORKOUTS.crosstraining.img} title={WORKOUTS.crosstraining.title} type={"fitness"} href={WORKOUTS.crosstraining.href} desc={WORKOUTS.crosstraining.shortDesc} />
                    </Col>
                    <Col>
                        <CardLayout img={WORKOUTS.bjj.img} title={WORKOUTS.bjj.title} type={"küzdősport"} href={WORKOUTS.bjj.href} desc={WORKOUTS.bjj.shortDesc} />
                    </Col>
                    <Col>
                        <CardLayout img={WORKOUTS.kettlebell.img} title={WORKOUTS.kettlebell.title} type={"fitness"} href={WORKOUTS.kettlebell.href} desc={WORKOUTS.kettlebell.shortDesc} />
                    </Col>
                    {/* <Col>
                        <CardLayout img={cross} title={WORKOUTS.crosstraining.title} type={"küzdősport"} href={WORKOUTS.crosstraining.href} desc={WORKOUTS.crosstraining.desc} />
                    </Col> */}
                </Row>
            </Container>
        </>
    );
};

export default Workouts;
