import { Container } from "react-bootstrap";
import { Timetable } from "../../components/Timetable/Timetable";
import "./Prices.css"
import { COACHES } from "../../misc/mocked";

const PRICES = {
	fitness: {
		title: "Funkcionális erőfejlesztés, Kardio thai box",
		color: "yellow",
		price: [
			{ amount: '10', title: 'alkalamas bérlet', price: '28 000 HUF', desc: '45 napig érvényes' },
			{ title: 'napijegy', price: '3 500 HUF' },
		]
	},
	bjj: {
		title: "Brazil jiu-jitsu",
		color: "red",
		price: [
			{ title: 'korlátlan bérlet', price: '29 000 HUF', desc: '30 napig érvényes' },
			{ amount: '12', title: 'alkalamas bérlet', price: '23 000 HUF', desc: '30 napig érvényes' },
			{ amount: '8', title: 'alkalamas bérlet', price: '18 000 HUF', desc: '30 napig érvényes' },
			{ title: 'napijegy', price: '3 000 HUF' },
		]
	}
}

const Prices = () => {
	return (
		<>
			<Container className="p-3 prices-wrap">
				<h1 className="prices-title">áraink</h1>
				<h4 className="prices-sub-title">bérletek</h4>

				{Object.entries(PRICES).map((group, idx) =>
				(<div key={idx}>
					<div className="price-title">{group[1].title}</div>
					{group[1].price.map((elem, idxA) => (
						<div key={idxA} className={`price-line d-md-flex justify-content-md-between mx-auto`}>
							<div className={`orbitron-bold big-num ${group[1].color}`}>{elem.amount ? elem.amount : ''}</div>
							<div className="prices-desc">
								<h4>{elem.title}</h4>
								{elem.desc && <p>{elem.desc}</p>}
							</div>
							<div className="prices-separator"></div>
							<div className="prices-price">
								<h4>{elem.price}</h4>
							</div>
						</div>
					))}
				</div>))}

				<h4 className="prices-sub-title">személyi edzések</h4>
				<div className="price-title">Ár/óra/fő</div>
				{Object.entries(COACHES).map((coach, idx) => {
					if (coach[1].personaltrainer)
						return (<div key={idx}>
							<div key={idx} className={`price-line d-md-flex justify-content-md-between mx-auto`}>
								<div className={`orbitron-bold big-num`}>
									<div className={`prices-triangle ${coach[0] === 'pongracz_adam' ? `t-yellow` : ``}`}>
										<div className="prices-triangle-tiny"></div>
									</div>
								</div>
								<div className="prices-desc">
									<h4>{coach[1].familyName} {coach[1].forName}</h4>
									<p>{coach[1].personalTrainType}</p>
									<p>{coach[1].phone}</p>
								</div>
								<div className="prices-separator"></div>
								<div className="prices-price">
									{coach[1].prices.map((elem, idxA) => (
										<div key={idxA} className="personal-train-prices d-flex">
											<p className="personal-train-title">{elem.name}</p>
											<h4>{elem.price}</h4>
										</div>
									))}
								</div>
							</div>
						</div>
						)
					return <></>
				})}
				<h4 className="prices-sub-title">FDM (FASCIA KEZELÉS)</h4>
				<div className="price-title"></div>
				<div className={`price-line d-md-flex justify-content-md-between mx-auto`}>
					<div className={`orbitron-bold big-num`}>
						<div className="prices-triangle t-white">
							<div className="prices-triangle-tiny"></div>
						</div>
					</div>
					<div className="prices-desc">
						<h4>{COACHES.pongracz_adam.familyName} {COACHES.pongracz_adam.forName}</h4>
						<p>{COACHES.pongracz_adam.phone}</p>
					</div>
					<div className="prices-separator"></div>
					<div className="prices-price">
						<div className="personal-train-prices d-flex">
							<p className="personal-train-title">50 perc</p>
							<h4>10 000 HUF</h4>
						</div>
					</div>
				</div>
			</Container >
			<Timetable singUp={true} />
		</>
	);
};

export default Prices;
