import { Container, Nav } from "react-bootstrap";
import { Btn } from "../Button/Button";
import "./SingUp.css";
import { PAGES } from "../../misc/constants";

export const SingUp = () => {
    return (
        <Container className="p-3">
            <div className="singup-wrap d-md-flex justify-content-md-between mx-auto">
                <div>
                    <h4>Jelentkezz első ingyenes edzésedre!</h4>
                    <p>Próbáld ki csoportos edzéseink bármelyikét első alkalommal teljesen ingyen!</p>
                </div>
                <div className="singup-btn-wrap">
                    <Nav.Link href={PAGES.singup.href} className="btn-nav-link mx-auto">
                        <Btn color={'red'} outline={false} label="Jelentkezem" />
                    </Nav.Link>
                </div>
            </div>
        </Container>
    )
}